const {
  Stitch,
  RemoteMongoClient,
  AnonymousCredential,
} = require('mongodb-stitch-browser-sdk');

const client = Stitch.initializeDefaultAppClient('til-wdc-dev-fwbxx');

const mongodb = client.getServiceClient(
  RemoteMongoClient.factory,
  'til-wdcs',
);
const db = mongodb.db('til-wdc-stats');

const auth = new Promise((resolve, reject) => {
  client.auth
    .loginWithCredential(new AnonymousCredential())
    .then(resolve)
    .catch(reject)
})

const methods = {
  db,
  auth,
};

module.exports = methods;
